<template>
  <div class="flex h-full flex-col">
    <v-header class="w-full" />
    <v-container class="flex w-full flex-grow gap-8">
      <main class="flex-grow">
        <slot />
      </main>
    </v-container>
    <v-footer class="w-full" />
  </div>
</template>

<script setup lang="ts"></script>
